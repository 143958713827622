import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { graphql } from 'gatsby';
import { compose } from 'ramda';
import Markdown from 'react-markdown';
import ConsultingEnquiryForm from '../components/consultingEnquiry/consultingEnquiryForm';
import Pinpoint from '../svg/pinpoint.svg';
import Phone from '../svg/phone.svg';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, LAPTOP, MOBILE, TABLET, media,
} from '../constants/screens';
import {
  BLACK, BLUE, DARK_SCHEME, WHITE,
} from '../constants/colors';
import withCustomLayout from '../enhancers/withCustomLayout';
import withStickyHeader from '../enhancers/withStickyHeader';

const ConsultingEnquiryPage = (props) => {
  const {
    data: {
      form: {
        title,
        subTitle,
        phone,
        address: { md },
        alert,
        image: { file: { localFile: { childImageSharp: { sizes } } } },
        ...rest
      },
    },
  } = props;

  return (
    <Container>
      <MainContent>
        <Content>
          <Title>
            {title}
          </Title>
          <Subtitle>
            {subTitle}
          </Subtitle>
          <Location>
            <Pinpoint />
            <TextWrapper>
              <Text source={md} />
            </TextWrapper>
          </Location>
          <PhoneContact>
            <Phone />
            <TextWrapper>
              <Text>{phone}</Text>
            </TextWrapper>
          </PhoneContact>
          <ImageWrapper>
            <Image sizes={sizes} alt="SlashData" />
          </ImageWrapper>
        </Content>
        <ConsultingEnquiryForm data={rest} alert={alert} />
      </MainContent>
    </Container>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  
  @media screen and (max-width: ${TABLET}) {
    margin-left: 6%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${WHITE};
  padding: 300px 5% 0 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 220px 5% 0;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 0 5%;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: 1600px) {
    padding: 225px 5% 0 5%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.45;
  
  @media screen and (max-width: ${TABLET}) {
    > div:last-child {
      justify-self: flex-end;
    }
  }
`;

const Title = styled.h1`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  margin-left: 55px;
  margin-top: 20px;
  color: ${BLUE};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
    margin-left: 0;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const Subtitle = styled.p`
  display: flex;
  margin: 25px 20px 0 55px;
  text-align: left;
  line-height: normal;
  letter-spacing: normal;
  color: ${BLACK};
  font-weight: 300;
  font-size: 20px;  
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-left: 25px;
  
  > svg {
    margin-right: 10px;
    height: 30px;
    width: 24px;
    
    & path {
      fill: ${BLUE};
    }
  }
  
  @media screen and (max-width: ${TABLET}) {
    align-items: flex-start;
    justify-content: center;
    margin-left: 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  white-space: pre;
`;

const Text = styled(Markdown)`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${BLACK};
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    max-width: 300px;
    min-width: 200px;
    width: 100%;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

const PhoneContact = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-bottom: 110px;
  
  > svg {
    margin-right: 10px;
    height: 23px;
    width: 24px;
    
    & path {
      fill: ${BLUE};
    }
  }
  
  @media screen and (max-width: ${TABLET}) {
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
`;

ConsultingEnquiryPage.propTypes = {
  data: PropTypes.shape({
    form: PropTypes.shape().isRequired,
  }).isRequired,
};

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(ConsultingEnquiryPage);

// eslint-disable-next-line
export const query = graphql`
    query ConsultingEnquiryPageQuery {
        form(name: { regex: "/consulting enquiry/" }) {
            title
            subTitle
            recaptcha
            alert {
                message
                type
            }
            image {
                file {
                    localFile {
                        childImageSharp {
                            sizes(maxWidth: 600 ) {
                                ...GatsbyImageSharpSizes_noBase64
                            }
                        }
                    }
                }
            }
            privacy {
                md
            }
            showEmailMe
            formFields {
                id: _id
                required
                htmlName
                selectOptions {
                    id: _id
                    value
                    label
                }
                label {
                    md
                    html
                }
                type
            }
            checkboxes {
                label
                required
                formFields {
                    id: _id
                    required
                    htmlName
                    label {
                        md
                        html
                    }
                    type
                }
            }
            emailMe {
                required
                htmlName
                label {
                    md
                    html
                }
                type
            }
            address {
                md
            }
            phone
            submitLabel
        }
    }
`;
